/* General Styles for Home Component */


.home-container {
    font-family: 'Poppins', sans-serif;
    color: #dcdcdc;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.2);
    background-color: #201E43;
    width: 77%;
    max-width: 77%;
    margin-left: 158px;
    box-sizing: border-box;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    position: fixed;
    top: 0;
    left: 9%;
    overflow: auto; /* Habilita el scroll cuando sea necesario */
    max-height: 97vh; /* Limita la altura del contenedor al 100% del viewport */
}

.main-content {
    font-family: 'Poppins', sans-serif;
    background-color: #134B70;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    box-sizing: border-box;
    padding: 30px 20px;
    overflow: auto;
}




.main-content > h2:nth-child(1) {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-size: 36px;
  font-weight: 300;
  line-height: 50px;
  color: #d9d8d8;
  margin-bottom: 15px;
}

.main-content > h2:nth-child(2) {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 26px;
    font-weight: 300;
    line-height: 50px;
    color: #d9d8d8;
    margin-bottom: 15px;
}

.main-content > h3:nth-child(3),
.main-content > h3:nth-child(6){
    font-family: 'Poppins', sans-serif;
    text-align: left;
    font-size: 20px;
    font-weight: 150;
    line-height: 50px;
    color: #d9d8d8;
    margin-bottom: 15px;
}
.account-info-user > h3:nth-child(1),
.account-info > h3:nth-child(2){
    font-family: 'Poppins', sans-serif;
    text-align: left;
    font-size: 25px;
    font-weight: 300;
    line-height: 50px;
    color: black;
    margin-bottom: 15px;
}

.main-content > a:nth-child(4) { 
    color: #d9d8d8;
    font-family: 'Poppins', sans-serif;
    width: auto;
    display: inline-block;
}

#select-options {
    background-color: #508C9B;
    font-family: 'Poppins', sans-serif;
}
/* Select and Button Styles */
.select {
    width: auto;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #333;
    border-radius: 5px;
    font-size: 16px;
    color: #ffffff;
    appearance: none; /* Quita la flecha por defecto en algunos navegadores */
}

/* Para aplicar estilos al option en navegadores que lo permiten */
.select option {
    color: #ffffff;
    padding: 10px;
    border: none; /* Quita el borde si es aplicable */
}

/* Quita el contorno cuando el select está enfocado */
.select:focus {
    outline: none; /* Quita el contorno del borde del select */
    border-color: #444444; /* Cambia el color del borde si lo deseas */
}

/* Quita el contorno del focus para opciones en algunos navegadores */
.select option:focus {
    outline: none; /* Elimina el contorno en el foco */
}

.button-new-account {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #EEEEEE; /* Button color matching the login theme */
    color: #080710; /* Text color for button */
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 15px;
    font-weight: 150;
    width: auto;
    
}

.button-new-account:hover {
    background-color: #256b9b; /* Darker shade on hover */
}

.account-info-user{
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    box-sizing: border-box;
    padding: 30px 20px;
}

/* Table Styles */
.table-container {
    overflow-x: auto; /* Allows horizontal scrolling for the table */
    max-width: 100%; /* Ensures the container does not exceed the viewport width */
    margin-top: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px; /* Ensures the table has a minimum width for horizontal scrolling */
}

table, th, td {
    border: 1px solid #4a5d77; /* Slightly lighter border color for better contrast */
}

th, td {
    padding: 12px;
    text-align: left;
}

/* Header Styles */
.table-container > table:nth-child(1) > thead:nth-child(1)  {
    background-color: #4d759d
}

.table-container > table:nth-child(1) > tbody:nth-child(2) {
    background-color: #2c3e50;
}

/* Zebra Striping and Hover Styles */
tbody tr:nth-child(even) {
    background-color: #2c3e50; /* Slightly lighter color for even rows */
}
.table-container > table:nth-child(1) > tbody:nth-child(2) {
    background-color: #5e666f;
    color: black;
}

tbody tr:hover {
    background-color: #34495e; /* Darker hover effect for better visibility */
}

/* Scroll Bar Styles (for WebKit browsers) */
.table-container::-webkit-scrollbar {
    height: 12px; /* Height of the scrollbar */
}

.table-container::-webkit-scrollbar-thumb {
    background-color: #4a5d77; /* Scrollbar thumb color */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.table-container::-webkit-scrollbar-track {
    background-color: #2c3e50; /* Track color behind the scrollbar thumb */
}


/* Charts Wrapper */
div.chart-container:nth-child(1) > h3:nth-child(1),
.charts-wrapper > div:nth-child(2) > h3:nth-child(1) {
    font-family: 'Poppins', sans-serif;
    text-align: left;
    font-size: 20px;
    font-weight: 300;
    line-height: 100px;
    color: white;
    margin-bottom: 15px;
}

.charts-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.chart-container, .line-chart-container {
    flex: 1;
    margin: 0 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .home-container {
        padding: 10px;
        margin: 0;
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        top: 0;
        left: 0;
        border-radius: 0; /* Quitar el borde redondeado en móviles */
        position: fixed; /* Cambiar la posición a relativa */
        box-shadow: none; /* Opcional: Eliminar la sombra para un diseño más limpio */
        overflow: auto; /* Asegurar que el contenido sea visible */
    }

    .main-content {
        padding: 15px;
        background-color: #134B70;
        border-radius: 10px;
        box-shadow: none;
        overflow: visible;
    }

    .button {
        width: 100%;
        padding: 12px;
        font-size: 18px;
    }

    .select {
        width: 100%;
    }

    table, th, td {
        font-size: 14px;
    }

    .table-container {
        overflow-x: auto; /* Habilitar el scroll horizontal para la tabla */
        -webkit-overflow-scrolling: touch; /* Suavizar el scroll en dispositivos móviles */
    }

    .table-container table {
        min-width: 600px; /* Asegurar que la tabla tenga un ancho mínimo */
    }

    .charts-wrapper {
        flex-direction: column;
    }

    .chart-container, .line-chart-container {
        margin: 10px 0; /* Ajustar el margen para gráficos apilados */
    }
}

