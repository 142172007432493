/* Estilo base para el componente CrearCuenta */
.crear-cuenta-container {
    padding: 20px;
    margin: 0 auto;
    max-width: 600px;
    font-family: Arial, sans-serif;
}

h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="text"],
input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button {
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    color: #fff;
    background-color: #334558;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

form {
    display: flex;
    flex-direction: column;
}

form > div {
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .crear-cuenta-container {
        padding: 15px;
    }

    h2 {
        font-size: 20px;
    }

    input[type="text"],
    input[type="number"] {
        font-size: 14px;
    }

    button {
        font-size: 14px;
        padding: 8px 12px;
    }
}
