.line-chart-container {
    display: flex;
    flex-direction: column; /* Cambia la dirección del contenido a columna */
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    max-width: 100%; /* Tamaño máximo del gráfico */
    margin: 0 auto; /* Centra el gráfico horizontalmente */
}

@media (max-width: 768px) {
    .line-chart-container {
        max-width: 90%; /* Ajusta el tamaño máximo para pantallas más pequeñas */
    }
}

@media (max-width: 480px) {
    .line-chart-container {
        max-width: 100%; /* Usa el 100% del ancho en pantallas muy pequeñas */
        height: 50vh; /* Hace que el contenedor tome el 50% de la altura de la ventana */
        justify-content: center; /* Centra el contenido horizontalmente */
        align-items: center; /* Centra el contenido verticalmente */
    }
}

/* Estilos para el contenedor de ambos gráficos */
.charts-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    gap: 20px; /* Espacio entre los gráficos */
}

@media (max-width: 768px) {
    .charts-wrapper {
        flex-direction: column;
        align-items: center;
    }
}
