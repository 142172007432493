/* Estilo base para el componente Gestion */
.gestion-container {
    font-family: 'Poppins', sans-serif;
    color: #dcdcdc;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.2);
    background-color: #201E43;
    width: 77%;
    max-width: 77%;
    margin-left: 158px;
    box-sizing: border-box;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    position: fixed;
    top: 0;
    left: 9%;
    overflow: auto; /* Habilita el scroll cuando sea necesario */
    max-height: 97vh; /* Limita la altura del contenedor al 100% del viewport */
}

.gestion-content{
    font-family: 'Poppins', sans-serif;
    background-color: #134B70;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    box-sizing: border-box;
    padding: 30px 20px;
    overflow: auto;
}

h2 {
    font-size: 26px;
    margin-bottom: 20px;
    color: #343a40;
    text-align: center;
}

h3 {
    font-size: 22px;
    margin-bottom: 15px;
    color: #495057;
}

.gestion-content > div:nth-child(2) > label:nth-child(1),
.form-new-movement > h3:nth-child(1) {
    font-family: 'Poppins', sans-serif;
    text-align: left;
    font-size: 20px;
    font-weight: 150;
    line-height: 50px;
    color: #d9d8d8;
    margin-bottom: 15px;
}


#cuenta {
    width: auto;
}
.form-new-movement {
    display: grid;
    justify-content: center;
}

#cuenta,
#tipoMovimiento,
#cuentaDestino,
#categoriaId,
#tipoGastoId {
    background-color: #508C9B;
    font-family: 'Poppins', sans-serif;
}

#descripcion{
    color: black;
    background-color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 150;
}


select,
input[type="number"] {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    box-sizing: border-box;
    background-color:rgba(255, 255, 255, 0.2);
    font-size: 16px;
    transition: border-color 0.3s ease;
}


select:focus,
input[type="number"]:focus {
    border-color: #007bff;
    outline: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

button {
    padding: 12px 18px;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

button:active {
    background-color: #004080;
}

form {
    display: flex;
    flex-direction: column;
}

form > div {
    margin-bottom: 15px;
}

textarea {
    width: 100%;
    height: 100px;
    padding: 12px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    resize: none; /* Elimina el redimensionamiento desde la esquina inferior derecha */
    box-sizing: border-box;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

textarea:focus {
    border-color: #007bff;
    outline: none;
}

.mensaje {
    margin-top: 15px;
    font-size: 16px;
    color: #d9534f; /* Rojo para mensajes de error */
    text-align: center;
}

/* Estilo para el enlace de "Volver al Inicio" */
.back-link {
    display: grid;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin: 5%;
}

.back-link:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.back-link:active {
    background-color: #004080;
}

@media (max-width: 768px) {
    .gestion-container {
        padding: 10px;
        margin: 0;
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        top: 0;
        left: 0;
        border-radius: 0; /* Quitar el borde redondeado en móviles */
        position: fixed; /* Cambiar la posición a relativa */
        box-shadow: none; /* Opcional: Eliminar la sombra para un diseño más limpio */
        overflow: auto; /* Asegurar que el contenido sea visible */
    }

    h2, h3 {
        font-size: 20px;
    }

    select, input[type="number"], textarea {
        font-size: 14px;
    }

    button {
        font-size: 14px;
        padding: 10px 15px;
    }
}
