/* General Styles for MovimientosCuenta Component */
.movement-container {
    font-family: 'Poppins', sans-serif;
    color: #dcdcdc;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.2);
    background-color: #201E43;
    width: 77%;
    max-width: 77%;
    margin-left: 158px;
    box-sizing: border-box;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    position: fixed;
    top: 0;
    left: 9%;
    overflow-y: auto; /* Habilita el scroll cuando sea necesario */
    max-height: 97vh; /* Limita la altura del contenedor al 100% del viewport */
}

.movement-content {
    font-family: 'Poppins', sans-serif;
    background-color: #134B70;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    box-sizing: border-box;
    padding: 30px 20px;
    overflow: auto;
}

#select-account-movement{
    background-color: #508C9B;
    font-family: 'Poppins', sans-serif;
    width: auto;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #333;
    border-radius: 5px;
    font-size: 16px;
    color: #ffffff;
    appearance: none; /* Quita la flecha por defecto en algunos navegadores */
}
.movement-content > h3,
.movement-content > div:nth-child(5) > h3:nth-child(1),
.form-update-movement > h3:nth-child(1){

    font-family: 'Poppins', sans-serif;
    text-align: left;
    font-size: 20px;
    font-weight: 150;
    line-height: 50px;
    color: #d9d8d8;
    margin-bottom: 15px;
}

select {
    padding: 10px;
    margin-bottom: 20px; /* Margin for spacing */
    border: 1px solid #ccc; /* Light border */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Font size for better readability */
}

button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px; /* Rounded corners */
    background-color: #b0bec5; /* Softer button color */
    color: #fff; /* Text color */
    font-size: 16px; /* Font size */
    cursor: pointer;
    margin-right: 10px; /* Margin between buttons */
    margin-bottom: 0px; /* Margin for spacing */
}

button:hover {
    background-color: #90a4ae; /* Slightly darker shade on hover */
}
/* Header Styles */
.table-container-movement {
    overflow-x: auto;
}


.table-container-movement > table:nth-child(1) > thead:nth-child(1)  {
    background-color: #134B70
}

.table-container-movement > table:nth-child(1) > tbody:nth-child(2) {
    background-color: #2c3e50;
}

/* Zebra Striping and Hover Styles */
tbody tr:nth-child(even) {
    background-color: #508C9B; /* Slightly lighter color for even rows */
}
.table-container-movement > table:nth-child(1) > tbody:nth-child(2) {
    background-color: #EEEEEE;
    color: black;
}

/* Scroll Bar Styles (for WebKit browsers) */
.table-container-movement::-webkit-scrollbar {
    height: 12px; /* Height of the scrollbar */
}

.table-container-movement::-webkit-scrollbar-thumb {
    background-color: #4a5d77; /* Scrollbar thumb color */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.table-container-movement::-webkit-scrollbar-track {
    background-color: #2c3e50; /* Track color behind the scrollbar thumb */
}

table {
    width: 100%;
    border-collapse: collapse; /* Collapse borders */
    min-width: 1000px; /* Minimum width to enable horizontal scroll */
}

table, th, td {
    border: 1px solid #ddd; /* Light border for table */
}

th, td {
    padding: 15px; /* Padding inside cells */
    text-align: left; /* Align text to the left */
}

thead {
    background-color: #f4f4f4; /* Light header background */
}

tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Zebra striping */
}

tbody tr:hover {
    background-color: #eaeaea; /* Hover effect for rows */
}

/* Pagination Styles */
.pagination {
    margin-top: 20px; /* Margin above pagination */
    display: flex;
    justify-content: center; /* Center pagination buttons */
    align-items: center;
}

.pagination button {
    padding: 10px 15px;
    border: 1px solid #ccc; /* Border for pagination buttons */
    background-color: #e0e0e0; /* Background color for buttons */
    color: #333; /* Text color */
    font-size: 16px; /* Font size */
    cursor: pointer;
    margin: 0 5px; /* Margin between buttons */
}

.pagination button:hover {
    background-color: #d0d0d0; /* Slightly darker shade on hover */
}

.pagination span {
    font-size: 16px; /* Font size for page numbers */
    margin: 0 10px; /* Margin around page numbers */
}

/* Form Styles */
.form-update-movement {
    font-family: 'Poppins', sans-serif;
    text-align: left;
    font-size: 20px;
    font-weight: 150;
    line-height: 20px;
    color: #d9d8d8;
    margin-bottom: 15px;
    display: grid;
    justify-content: center;
}

.form-update-movement div {
    margin-bottom: 15px; /* Margin below form-update-movement fields */
}

label {
    display: block;
    margin-bottom: 5px; /* Margin below labels */
    font-weight: bold; /* Bold font for labels */
}

input {
    padding: 10px;
    border: 1px solid #ccc; /* Light border */
    border-radius: 5px; /* Rounded corners */
    width: 100%; /* Full width input fields */
    box-sizing: border-box; /* Include padding in width */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .movement-container {
        padding: 10px;
        margin: 0;
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        top: 0;
        left: 0;
        border-radius: 0; /* Quitar el borde redondeado en móviles */
        position: fixed; /* Cambiar la posición a relativa */
        box-shadow: none; /* Opcional: Eliminar la sombra para un diseño más limpio */
        overflow: auto; /* Asegurar que el contenido sea visible */
    }

    .movement-content{
        padding: 15px;
        background-color: #134B70;
        border-radius: 10px;
        box-shadow: none;
        overflow: visible;
    }
    button {
        width: 100%; /* Full width buttons on smaller screens */
        padding: 12px;
        font-size: 18px; /* Larger font size for better touch targets */
    }

    select {
        width: 100%; /* Full width select on smaller screens */
    }

    table {
        min-width: 600px; /* Adjust minimum width for smaller screens */
    }

    .pagination button {
        width: 100%; /* Full width pagination buttons on smaller screens */
        padding: 12px;
        font-size: 18px; /* Larger font size for better touch targets */
    }
}
