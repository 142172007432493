/* General Styles for CuentasCliente Component */
.container-accounts {
    font-family: 'Poppins', sans-serif;
    color: #dcdcdc;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.2);
    background-color: #201E43;
    width: 77%;
    max-width: 77%;
    margin-left: 158px;
    box-sizing: border-box;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    position: fixed;
    top: 0;
    left: 9%;
    overflow: auto; /* Habilita el scroll cuando sea necesario */
    max-height: 97vh; /* Limita la altura del contenedor al 100% del viewport */
}

h2 {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 36px;
    font-weight: 300;
    line-height: 50px;
    color: #d9d8d8;
    margin-bottom: 15px;
}


/* Table Styles */
.table-container-accounts {
    overflow-x: auto; /* Allows horizontal scrolling for the table */
    max-width: 100%; /* Ensures the container does not exceed the viewport width */
    margin-top: 20px;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.2);
}

table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px; /* Ensures the table has a minimum width for horizontal scrolling */
}

table, th, td {
    border: 1px solid #4a5d77; /* Slightly lighter border color for better contrast */
}

th, td {
    padding: 12px;
    text-align: left;
}

/* Header Styles */
.table-container-accounts > table:nth-child(1) > thead:nth-child(1)  {
    background-color: #134B70
}

.table-container-accounts > table:nth-child(1) > tbody:nth-child(2) {
    background-color: #2c3e50;
}

/* Zebra Striping and Hover Styles */
tbody tr:nth-child(even) {
    background-color: #508C9B; /* Slightly lighter color for even rows */
}
.table-container-accounts > table:nth-child(1) > tbody:nth-child(2) {
    background-color: #EEEEEE;
    color: black;
}

tbody tr:hover {
    background-color: #34495e; /* Darker hover effect for better visibility */
}

/* Scroll Bar Styles (for WebKit browsers) */
.table-container-accounts::-webkit-scrollbar {
    height: 12px; /* Height of the scrollbar */
}

.table-container-accounts::-webkit-scrollbar-thumb {
    background-color: #4a5d77; /* Scrollbar thumb color */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.table-container-accounts::-webkit-scrollbar-track {
    background-color: #2c3e50; /* Track color behind the scrollbar thumb */
}

.editar-cuenta-form,
div.editar-cuenta-form:nth-child(1) > h3:nth-child(1){
    font-family: 'Poppins', sans-serif;
    text-align: left;
    font-size: 20px;
    font-weight: 150;
    line-height: 50px;
    color: #d9d8d8;
    margin-bottom: 15px;
    justify-content: center;
    display: grid;
}

div.editar-cuenta-form:nth-child(1) > form:nth-child(2) > label {
    font-family: 'Poppins', sans-serif;
    text-align: left;
    font-size: 16px;
    font-weight: 150;
    line-height: 20px;
    color: #d9d8d8;
    margin-bottom: 15px;
}



button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #b0bec5;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
}

button:hover {
    background-color: #90a4ae;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .container-accounts {
        padding: 10px;
        margin: 0;
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        top: 0;
        left: 0;
        border-radius: 0; /* Quitar el borde redondeado en móviles */
        position: fixed; /* Cambiar la posición a relativa */
        box-shadow: none; /* Opcional: Eliminar la sombra para un diseño más limpio */
        overflow: auto; /* Asegurar que el contenido sea visible */
    }


    .table-container-accounts {
        overflow-x: auto; /* Habilitar el scroll horizontal para la tabla */
        -webkit-overflow-scrolling: touch; /* Suavizar el scroll en dispositivos móviles */
    }

    .table-container-accounts table {
        min-width: 600px; /* Asegurar que la tabla tenga un ancho mínimo */
    }

    table {
        min-width: 600px; /* Adjust minimum width for smaller screens */
    }

    th, td {
        padding: 10px;
        font-size: 14px;
    }

    button {
        padding: 12px;
        font-size: 14px;
        width: 100%; /* Full width buttons on smaller screens */
        margin-bottom: 10px; /* Space between buttons */
    }
}
